import { Typography, Input, Button, Form, Select } from 'antd';
import { useContext, useState, useRef } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;


const OptionUrl = (isEnter) => {
  
  const [valStatus, setValStatus] = useState('sucess');
  const [prefix, setPrefix] = useState('http://');
  const { setUrlQR, isValidQR, setIsValidQR } = useContext(QrContext);
  const ref = useRef(null); 

  const validator = (objUrl = '') => {
    let regex = new RegExp(/(?<g1>http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(?<g2>[a-z0-9])+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(?<g3>:[0-9]{1,5})?(?<g4>\/.*)?$/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
          if(teste[1] === undefined) {
        result = prefix + teste[0];
      } else {
        result = teste[0];
      }
      console.log( result);
  
      setValStatus('sucess');
      setUrlQR(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setUrlQR('invalid');
    }
  };
  
  const handleOptSelect = (v) => {
    setPrefix(v === 'http://' ? 'http://' : 'https://');
    let va = ref.current.state.value;
    validator(va);
  };

  const selectBefore = (
    <Select defaultValue="http://" className="select-before" onChange={handleOptSelect}>
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );


  
  const handleChange = (e) => {
    e.preventDefault();
    validator(e.target.value);
  }

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc" > Entre com o URL e seu QR Code será criado!</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'URL inválida!'}
      >
        <Input ref={ref} addonBefore={selectBefore} className="txtAreaOpt" 
          placeholder="midiacode.com" 
          onChange={handleChange}
        />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> {configData.BTN_AZUL} </Button>
    </Typography>
  </>
);

}

export default OptionUrl;