import { Typography, Input, Button, Form } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

// (?:https:\/\/)(?:www\.)?(twitter\.com\/)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([^/?]*)(\/)?(\?)?(\w*)?(\.)?(\=)?(\w*)?(\/)?(\w*)?

const { Text } = Typography;
const { Item } = Form;
const OptionTwitter = (isEnter) => {
  
  const { setTwitterQR, isValidQR, setIsValidQR } = useContext(QrContext);
  const [valStatus, setValStatus] = useState('sucess');

  
  const validator = (objUrl = '') => {
    let regex = new RegExp(/^(?:[https?://])?(?:(\w*)?$)/g);
    let result = 'https://twitter.com/';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result += teste[0];
      // console.log( teste);
      setValStatus('sucess');
      setTwitterQR(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setPodcastQR('invalid');
    }
  };
  const handleChange = (e) => {
    e.preventDefault();

    validator(e.target.value);

  }

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-default txt-desc">Entre com o seu Twitter</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'URL inválida! não precisa por https://twitter.com/'}
      >
      <Input addonBefore="https://twitter.com/" onChange={handleChange} className="margins" />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> 
        {configData.BTN_AZUL}
      </Button>
    </Typography>
  </>
);

}

export default OptionTwitter;