import { Typography, Input, Button, Form } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

// ((?:(?:http?|ftp)[s]*:\/\/)[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)


const { Text } = Typography;
const { Item } = Form;

const OptionVideo = (isEnter) => {
  
  const { setVideoQR, isValidQR, setIsValidQR } = useContext(QrContext);
  const [valStatus, setValStatus] = useState('sucess');
  const validator = (objUrl = '') => {
    let regex = new RegExp(/((?:(?:http?|ftp)[s]*:\/\/)[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result = teste[0];
      console.log( result);
      setValStatus('sucess');
      setVideoQR(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setVideoQR('invalid');
    }
  };
  const handleChange = (e) => {
    e.preventDefault();

    validator(e.target.value);

  }

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Entre com o link do seu vídeo.</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'URL inválida! É necessário incluir URL com prefixo http://|https://'}
      >
        <Input onChange={handleChange} className="txtAreaOpt" placeholder="Copie e Cole o link do seu vídeo aqui" />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> {configData.BTN_AZUL} </Button>
    </Typography>
  </>
);

}

export default OptionVideo;