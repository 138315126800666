import React, { useContext, useEffect, useState } from 'react';
import { Typography, Input, Button, Form } from 'antd';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

// (?:[^\n](?:(\d{2,3})?(\+)?(\d{2,3})?(\s)?(\()?)(?:\d{2,3})(?:(\s)?(\))?(\s)?(\-)?)(?:\d{4,5})(?:(\-?)(\d{4,5})?(\d*)?)(?:(\s)?(\-)?(\d{2,3})(\s)(\d{2,3})?)?)


const { Text } = Typography;
const { TextArea } = Input;
const { Item } = Form;

const OptionSms = (isEnter) => {

  const [valStatus, setValStatus] = useState('sucess');
  
  const { setSmsQR, isValidQR, setIsValidQR } = useContext(QrContext);

  const [number, setNumber] = useState('');
  const [msg, setMsg] = useState('');

  
  const validator = (objUrl = '') => {
    let regex = new RegExp(/(?:[^\n](?:(\d{2,3})?(\+)?(\d{2,3})?(\s)?(\()?)(?:\d{2,3})(?:(\s)?(\))?(\s)?(-)?)(?:\d{4,5})(?:(-?)(\d{4,5})?(\d*)?)(?:(\s)?(-)?(\d{2,3})(\s)(\d{2,3})?)?)/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result = teste[0];
      console.log( result);
      setValStatus('sucess');
      setNumber(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setPodcastQR('invalid');
    }
  };

  useEffect(() => {
    setSmsQR(`SMSTO:${number}:${msg}`);
  }, [number, msg, setSmsQR]);
  // SMSTO:<PHONE>:<message>

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Coloque o número do seu celular + DDD e a mensagem que você quer enviar</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'Número inválido'}
      >
        <Input className="margins" placeholder="Número do Celular" onChange={
          (e) => {
            validator(e.target.value);
          }
        } />
      </Item>
      <TextArea rows={6} className="txtAreaOpt" placeholder="Mensagem" onChange={
        (e) => {
          setMsg(e.target.value);
        }
      } />
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> 
          {configData.BTN_AZUL}
        </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionSms;