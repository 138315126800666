import { Typography, Input, Button, Form } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const { Item } = Form;

const OptionYoutube = (isEnter) => {

  const [valStatus, setValStatus] = useState('sucess');
  const { setYoutubeQR, isValidQR, setIsValidQR  } = useContext(QrContext);

  const validator = (objYoutube = '') => {
    let regex = new RegExp(/(?<g0>^(?<prefix>https:\/\/)?(?<sufix>(www)?\.?(youtube\.com?|youtu\.be?))(\/))(?<g1>watch\?v=)?(?<g2>[A-Za-z0-9]{11})?([A-Za-z0-9]*)?(-)?(\w*)?(\/)?([A-Za-z0-9]*)?(-)?([A-Za-z0-9]*)?/g);
    let result = '';
    if(objYoutube.match(regex)) {
      let teste = regex.exec(objYoutube);
      result = teste[0];      
      setIsValidQR(true);
      setValStatus('sucess');
      setYoutubeQR(result);
    } else {
      setIsValidQR(false);
      setValStatus('error');
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    validator(e.target.value)
  }

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Entre com o link do seu vídeo no Youtube.</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'URL inválida!  É necessário incluir URL com prefixo https://youtube.com/|https://youtu.be/'}
      >
        <Input onChange={handleChange} className="txtAreaOpt" placeholder="Copie e Cole o link do seu vídeo aqui" />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> {configData.BTN_AZUL} </Button>
    </Typography>
  </>
);

}

export default OptionYoutube;