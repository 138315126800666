import React, { useContext, useEffect, useState } from 'react';
import { Typography, Input, Button, Form } from 'antd';
import configData from '../QRcodeAppConfig.json';

import QrContext from '../contextQrCode';

// ([0-9a-z]*)(\@)([0-9a-z]*)\.([0-9a-z]*)(\.)?([0-9a-z]*)?

const { Text } = Typography;
const { TextArea } = Input;
const { Item } = Form;

const OptionEmail = (isEnter) => {

  const { setEmailQR, isValidQR, setIsValidQR } = useContext(QrContext);

  const [valStatus, setValStatus] = useState('sucess');
  const [adress, setAdress] = useState('');
  const [subject, setSubject] = useState('');
  const [msg, setMsg] = useState('');

    
  const validator = (objUrl = '') => {
    let regex = new RegExp(/([0-9a-z]*)(@)([0-9a-z]*)\.([0-9a-z]*)(\.)?([0-9a-z]*)?/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result = teste[0];
      console.log( result);
      setValStatus('sucess');
      setAdress(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setPodcastQR('invalid');
    }
  };

  useEffect(() => {
    setEmailQR(`MATMSG:TO:${adress};SUB:${subject};BODY:${msg};;`);
  }, [adress, subject, msg, setEmailQR])

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Entre com os dados do email que você quer que seja enviado.</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'Endereço de email inválido.'}
      >
        <Input className="margins" placeholder="Endereço do seu email" onChange={
          (e) => {
            validator(e.target.value);
          }
        } />
      </Item>
      <Input className="margins" placeholder="Assunto do email" onChange={
        (e) => {
          setSubject(e.target.value);
        }
      } />
      <TextArea rows={6} className="txtAreaOpt" placeholder="Mensagem do email" onChange={
        (e) => {
          setMsg(e.target.value);
        }
      } />
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_3} target="_blank" className="margins" shape="round"  type="primary"> 
        {configData.BTN_AZUL}
        </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionEmail;