import { Typography, Input, Button, Form } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

// (?:https:\/\/)(?:www\.)facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([^/?]*)(\/)?(\?)?(\w*)?(\.)?(\=)?(\w*)?(\.)?(\w*)?

const { Text } = Typography;
const { Item } = Form;

const OptionFacebook = (isEnter) => {
  
  const {setFacebookQR, isValidQR, setIsValidQR} = useContext(QrContext);
  const [valStatus, setValStatus] = useState('sucess');

  
  const validator = (objUrl = '') => {
    let regex = new RegExp(/^(?:[https?://])?(?:(\w*)?$)/g);
    let result = 'https://facebook.com/';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      console.log(teste);
      result += teste[0];
      // console.log( teste);
      setValStatus('sucess');
      setFacebookQR(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setPodcastQR('invalid');
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    validator(e.target.value);
  }


  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-default txt-desc">Entre com o seu Facebook.</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'URL inválida! não precisa por https://facebook.com/'}
      >
        <Input addonBefore="https://facebook.com/" onChange={handleChange} className="margins" />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" className="margins" shape="round" type="primary"> 
      {configData.BTN_AZUL}
      </Button>
    </Typography>
  </>
);

}

export default OptionFacebook;