import { Typography, Input, Button } from 'antd';

import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const OptionAppStores = (isEnter) => {
  
  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-default txt-desc">Entre com os dados do seu app</Text>
      <Input className="margins" placeholder="Android Google Play Link"  />
      <Input className="margins" placeholder="Apple App Store link"  />
      <Input className="margins" placeholder="Link do seu site, caso a captura aconteça no computador"  />
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_3} target="_blank" className="margins" type="primary" shape="round"> 
          {configData.BTN_AZUL}
        </Button>
        <Button className="margins" shape="round" type="primary"> 
          Criar QRCode
        </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionAppStores;